<app-page-header>
  <ng-container title>Business Rules</ng-container>
  <ng-container toolbar>
    <button
      mat-raised-button
      color="primary"
      [disabled]="(!networkForm.dirty && !supplierForm.dirty) || loading || !selectedSupplier"
      (click)="initializeForms()"
      class="destructive"
    >
      CANCEL
      <mat-icon>do_not_disturb_on</mat-icon>
    </button>
    <button
      mat-raised-button
      color="primary"
      [disabled]="loading || !selectedSupplier"
      (click)="save()"
      class="constructive"
    >
      SAVE
      <mat-icon>save</mat-icon>
    </button>
  </ng-container>
</app-page-header>
<app-loading-indicator *ngIf="loading; else content"></app-loading-indicator>
<ng-template #content>
  <div class="detail-content">
    <app-om-card [minHeight]="'580px'">
      <app-om-card-content>
        <mat-tab-group>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="header-icon">stream</mat-icon>
              NETWORK
            </ng-template>

            <app-om-card class="business-rules-card">
              <app-om-card-content class="business-rules-card-content">
                <form [formGroup]="networkForm">
                  <div class="my-4 mat-card mat-elevation-z2">
                    <div class="title-text">General</div>
                    <div class="mat-card inset-mat-card">
                      <div class="row">
                        Close purchase order until&nbsp;
                        <span class="value-text">
                          {{ networkForm.controls.closePoUntilFulfilled.value ? 'all quantities' : 'all lines' }}
                        </span>
                        &nbsp;have been
                        <span class="font-weight-bold">&nbsp;booked</span>
                        <span class="valueText">.</span>
                      </div>
                      <div class="choice-slider">
                        <ui-switch
                          formControlName="closePoUntilFulfilled"
                          size="small"
                          defaultBgColor="white"
                          checkedLabel="&nbsp;&nbsp;&nbsp;"
                          uncheckedLabel="&nbsp;&nbsp;&nbsp;"
                        ></ui-switch>
                      </div>
                    </div>
                  </div>
                </form>
                <div class="my-4 mat-card mat-elevation-z2" *ngIf="orgMode !== orgModes.Lite">
                  <app-org-icon-setting title="Client Logo" [orgId]="customer.id"></app-org-icon-setting>
                </div>
                <div class="my-4 mat-card mat-elevation-z2" *ngIf="orgMode !== orgModes.Lite">
                  <app-network-access-key-setting
                    [orgId]="customer.id"
                    [apiAccessKeyInfo]="apiAccessKeyInfo"
                    [errorRestrictionsArr]="errorRestrictionsArrToChild"
                    (refreshApiIpRestrictions)="refreshApiIpRestrictions($event)"
                  ></app-network-access-key-setting>
                </div>

                <div class="my-4 mat-card mat-elevation-z2" *ngIf="orgMode !== orgModes.Lite">
                  <app-network-asn-trigger-setting
                    [orgId]="customer.id"
                    [asnTriggerDefinition]="asnTriggerDefinition"
                    (refreshAsnTrigger)="refreshAsnTrigger($event)"
                  ></app-network-asn-trigger-setting>
                </div>
              </app-om-card-content>
            </app-om-card>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="header-icon">domain</mat-icon>
              SUPPLIER
            </ng-template>
            <app-om-card class="business-rules-card edit-section">
              <!--<div class="row">-->
              <!--<div class="col">-->
              <!--<div class="form-field">-->
              <!--<div class="row">-->
              <!--<label class="detail-field-title">Supplier</label>-->
              <!--</div>-->
              <!--<div class="row detail-field-content">-->
              <!--<mat-form-field appearance="outline" class="extended-width">-->
              <!--<mat-select [(value)]="selectedSupplier" (selectionChange)="initializeForms()">-->
              <!--<mat-option *ngFor="let supplier of suppliers" [value]="supplier">{{supplier.name}}</mat-option>-->
              <!--</mat-select>-->
              <!--</mat-form-field>-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
              <!--</div>-->
              <app-om-card-content class="business-rules-card-content">
                <div class="title-text">Supplier</div>
                <div class="col-3 supplier-div">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select [(value)]="selectedSupplier" (selectionChange)="initializeForms()">
                      <mat-option *ngFor="let supplier of suppliers" [value]="supplier">{{ supplier.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <app-loading-indicator *ngIf="loadingSupplierForm; else supplierContent"></app-loading-indicator>
                <ng-template #supplierContent>
                  <form [formGroup]="supplierForm">
                    <div>
                      <div class="title-text">Thresholds</div>
                      <div class="mat-card inset-mat-card">
                        <div style="display: none">
                          <div>
                            <span class="value-text">{{ supplierForm.controls.containerFill.value }}%</span>
                            &nbsp;of container under/over
                            <span class="font-weight-bold">Fill Without Approval Required</span>
                          </div>
                          <div class="choice-slider">
                            <mat-slider
                              class="w-100"
                              min="1"
                              max="100"
                              step="step"
                              thumbLabel="true"
                              formControlName="containerFill"
                            ></mat-slider>
                          </div>
                          <div class="mt-4">
                            <span class="value-text">{{ supplierForm.controls.quantityShip.value }}%</span>
                            &nbsp;of allowed under/over
                            <span class="font-weight-bold">Quantity Shipped Without Approval</span>
                          </div>
                          <div class="choice-slider">
                            <mat-slider
                              class="w-100"
                              min="1"
                              max="100"
                              step="step"
                              thumbLabel="true"
                              formControlName="quantityShip"
                            ></mat-slider>
                          </div>
                        </div>
                        <div class="mt-4">
                          <span class="value-text">{{ supplierForm.controls.bookAndCargoDatesDiff.value }}</span>
                          &nbsp;days for Book by Date
                          <span class="font-weight-bold">From Cargo Ready Date</span>
                        </div>
                        <div class="choice-slider">
                          <mat-slider
                            class="w-100"
                            min="1"
                            max="10"
                            step="step"
                            thumbLabel="true"
                            formControlName="bookAndCargoDatesDiff"
                          ></mat-slider>
                        </div>
                        <div class="mt-4">
                          Alert when PO has not been booked within&nbsp;
                          <span class="value-text">{{ supplierForm.controls.daysToBook.value }}</span>
                          &nbsp;days
                          <span class="font-weight-bold">from Book By Date</span>
                          <span class="valueText">.</span>
                        </div>
                        <div class="choice-slider">
                          <mat-slider
                            class="w-100"
                            min="0"
                            max="90"
                            step="step"
                            thumbLabel="true"
                            formControlName="daysToBook"
                          ></mat-slider>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="title-text">Allowed Documents for Download</div>
                      <div *ngIf="documentTypes" class="mat-card inset-mat-card">
                        <div formArrayName="documentPermissions" style="max-width: 500px">
                          <ng-container *ngFor="let docType of documentTypes; index as j" [formGroupName]="j">
                            <div class="row document-row">
                              <div class="col-8 my-auto">{{ docType.description }}</div>
                              <div class="col-3 my-auto align-center">
                                <ui-switch
                                  formControlName="enabled"
                                  size="small"
                                  defaultBgColor="white"
                                  checkedLabel="&nbsp;&nbsp;&nbsp;"
                                  uncheckedLabel="&nbsp;&nbsp;&nbsp;"
                                ></ui-switch>
                              </div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="title-text">Allowed Edit Approved Booking</div>
                      <div class="mat-card inset-mat-card">
                        <div style="max-width: 500px">
                          <div class="row document-row">
                            <div class="col-8 my-auto">Allowed Edit Approved Booking</div>
                            <div class="col-3 my-auto align-center">
                              <ui-switch
                                formControlName="canEditApprovedBooking"
                                size="small"
                                defaultBgColor="white"
                                checkedLabel="&nbsp;&nbsp;&nbsp;"
                                uncheckedLabel="&nbsp;&nbsp;&nbsp;"
                              ></ui-switch>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="title-text">Purchase Order Defaults</div>
                      <div class="mat-card inset-mat-card">
                        <app-om-purchase-order-defaults
                          [form]="poDefaultsForm"
                          [manufacturers]="manufacturers"
                          [consignees]="consignees"
                          [supplierCode]="selectedSupplier?.code"
                        ></app-om-purchase-order-defaults>
                      </div>
                    </div>
                    <div>
                      <div class="title-text">Booking Defaults</div>
                      <div class="mat-card inset-mat-card">
                        <app-om-booking-defaults
                          [form]="bookingDefaultsForm"
                          [manufacturers]="manufacturers"
                          [consignees]="consignees"
                          [customer]="customer"
                          [forwarders]="forwarders"
                          [contacts]="contacts"
                          [supplierCode]="selectedSupplier?.code"
                        ></app-om-booking-defaults>
                      </div>
                    </div>
                    <div>
                      <div class="title-text">Booking Auto Approve</div>
                      <div class="mat-card inset-mat-card">
                        <app-booking-auto-approve [form]="supplierForm"></app-booking-auto-approve>
                      </div>
                    </div>
                  </form>
                  <div class="mat-card mt-5" *ngIf="orgMode !== orgModes.Lite && selectedSupplier">
                    <app-org-icon-setting title="Supplier Logo" [orgId]="selectedSupplier.id"></app-org-icon-setting>
                  </div>
                </ng-template>
              </app-om-card-content>
            </app-om-card>
          </mat-tab>
        </mat-tab-group>
      </app-om-card-content>
    </app-om-card>
  </div>
</ng-template>
