import { SaveEquipment } from '@models/equipment.model';
import { Equipment } from '@models/equipment.model';
import { PurchaseOrder } from '@models/purchase-order.model';
import { Organization } from '@models/organization.model';
import { Location } from './location.model';
import { Contact } from './contact.model';
import { BookedPurchaseOrderLine } from '@models/purchase-order-line.model';
import { BookingStatus } from '@models/helpers';

export class BasicBooking {
  id: number;
  networkId: number;
  bookingNumber: string;
  cwBookingNumber: string;
  bookingStatus: BookingStatus;
  isPriority: boolean;
  bookingDate: Date;
  customer: Organization;
  consignee: Organization;
  manufacturer: Organization;
  shipper: Organization;
  forwarder: Organization;
  forwarderContact: Contact;
  contacts: Contact[];
  contactIds: number[];
  contactNames: string;
  incoterm: string;
  cargoReadyDate: string;
  cargoReadyDateValue: Date;
  revisedCargoReadyDate: Date;
  estimatedDeliveryDate: string;
  estimatedDeliveryDateValue: Date;
  etd: string;
  etdValue: Date;
  eta: string;
  etaValue: Date;
  portOfDischarge: string;
  portOfLoading: string;
  portOfDestination: string;
  portOfOrigin: string;
  portOfReceipt: string;
  portOfLoadingTimezone: string;
  portOfDischargeTimezone: string;
  consigneeLocation: Location;
  customerLocation: Location;
  shipperLocation: Location;
  pickupLocation: Location;
  shipToLocation: Location;
  wayBillNumber: string;
  wayBillType: string;
  goodDescription: string;
  additionalTerms: string;
  containerMode: string;
  countryOfSupply: string;
  transportMode: string;
  releaseType: string;
  multiTransportModeConfirm: boolean;
  container: string;
  skuItemNumbers: string;
  isContainerized: boolean;
  canGenerateInvoice: boolean;
  cargoPickedUpDate: string;
  cargoPickedUpDateValue: Date;
  canEdit: boolean;
  isEditing: boolean;

  constructor(init?: Partial<BasicBooking>) {
    if (init?.id) this.id = init.id;
    if (init?.networkId) this.networkId = init.networkId;
    if (init?.bookingNumber) this.bookingNumber = init.bookingNumber;
    if (init?.cwBookingNumber) this.cwBookingNumber = init.cwBookingNumber;
    if (init?.bookingStatus || init?.bookingStatus === BookingStatus.Unsent) this.bookingStatus = init.bookingStatus;
    if (init?.isPriority) this.isPriority = init.isPriority;
    if (init?.bookingDate) this.bookingDate = init.bookingDate;
    if (init?.customer) this.customer = init.customer;
    if (init?.consignee) this.consignee = init.consignee;
    if (init?.manufacturer) this.manufacturer = init.manufacturer;
    if (init?.shipper) this.shipper = init.shipper;
    if (init?.forwarder) this.forwarder = init.forwarder;
    if (init?.forwarderContact) this.forwarderContact = init.forwarderContact;
    if (init?.contactIds) {
      this.contactIds = init.contactIds;
    } else if (init?.contacts) {
      this.contactIds = [...init.contacts.map(c => c.id).filter(c => c)];
    }
    if (init?.contactNames) this.contactNames = init.contactNames;
    if (init?.incoterm) this.incoterm = init.incoterm;
    if (init?.cargoReadyDateValue) {
      this.cargoReadyDateValue = init.cargoReadyDateValue;
    } else if (init?.cargoReadyDate) {
      this.cargoReadyDate = init.cargoReadyDate;
      this.cargoReadyDateValue = new Date(init.cargoReadyDate);
    }
    if (init?.estimatedDeliveryDateValue) {
      this.estimatedDeliveryDateValue = init.estimatedDeliveryDateValue;
    } else if (init?.estimatedDeliveryDate) {
      this.estimatedDeliveryDate = init.estimatedDeliveryDate;
      this.estimatedDeliveryDateValue = new Date(init.estimatedDeliveryDate);
    }
    if (init?.revisedCargoReadyDate) this.revisedCargoReadyDate = init.revisedCargoReadyDate;

    if (init?.etdValue) {
      this.etdValue = init.etdValue;
      this.etd = init.etdValue.toUTCString();
    } else if (init?.etd) {
      this.etd = init.etd;
      this.etdValue = new Date(init.etd);
    }
    if (init?.etaValue) {
      this.etaValue = init.etaValue;
      this.eta = init.etaValue.toUTCString();
    } else if (init?.eta) {
      this.eta = init.eta;
      this.etaValue = new Date(init.eta);
    }
    if (init?.cargoPickedUpDateValue) {
      this.cargoPickedUpDateValue = init.cargoPickedUpDateValue;
    } else if (init?.cargoPickedUpDate) {
      this.cargoPickedUpDate = init.cargoPickedUpDate;
      this.cargoPickedUpDateValue = new Date(init.cargoPickedUpDate);
    }
    if (init?.portOfDischarge) this.portOfDischarge = init.portOfDischarge;
    if (init?.portOfLoading) this.portOfLoading = init.portOfLoading;
    if (init?.portOfDestination) this.portOfDestination = init.portOfDestination;
    if (init?.portOfOrigin) this.portOfOrigin = init.portOfOrigin;
    if (init?.portOfReceipt) this.portOfReceipt = init.portOfReceipt;
    if (init?.portOfLoadingTimezone) this.portOfLoadingTimezone = init.portOfLoadingTimezone;
    if (init?.portOfDischargeTimezone) this.portOfDischargeTimezone = init.portOfDischargeTimezone;
    if (init?.consigneeLocation) this.consigneeLocation = init.consigneeLocation;
    if (init?.customerLocation) this.customerLocation = init.customerLocation;
    if (init?.shipperLocation) this.shipperLocation = init.shipperLocation;
    if (init?.pickupLocation) this.pickupLocation = init.pickupLocation;
    if (init?.shipToLocation) this.shipToLocation = init.shipToLocation;
    if (init?.wayBillNumber) this.wayBillNumber = init.wayBillNumber;
    if (init?.wayBillType) this.wayBillType = init.wayBillType;
    if (init?.goodDescription) this.goodDescription = init.goodDescription;
    if (init?.additionalTerms) this.additionalTerms = init.additionalTerms;
    if (init?.containerMode) this.containerMode = init.containerMode;
    if (init?.countryOfSupply) this.countryOfSupply = init.countryOfSupply;
    if (init?.transportMode) this.transportMode = init.transportMode;
    if (init?.releaseType) this.releaseType = init.releaseType;
    if (init?.container) this.container = init.container;
    if (init?.skuItemNumbers) this.skuItemNumbers = init.skuItemNumbers;
    this.multiTransportModeConfirm = init?.multiTransportModeConfirm ? init.multiTransportModeConfirm : false;
    this.isContainerized = init?.isContainerized ? init.isContainerized : false;
    this.canGenerateInvoice = init?.canGenerateInvoice ? init.canGenerateInvoice : false;
    this.canEdit = init?.canEdit ? init.canEdit : false;
    this.isEditing = init?.isEditing ? init.isEditing : false;
  }
}

// This model maps to the DTO.BookingDto
// on the API.
export class Booking extends BasicBooking {
  bookedEquipments: Equipment[];
  bookedPurchaseOrders: PurchaseOrder[];

  constructor(init?: Partial<Booking>) {
    super(init);
    this.bookedEquipments = init?.bookedEquipments ? init.bookedEquipments : [];
    this.bookedPurchaseOrders = init?.bookedPurchaseOrders ? init.bookedPurchaseOrders : [];
  }
}

export class SaveBooking extends BasicBooking {
  bookedEquipments: SaveEquipment[];
  bookedPoLines: BookedPurchaseOrderLine[];

  constructor(init?: Partial<SaveBooking>) {
    super(init);
    this.bookedEquipments = init?.bookedEquipments ? init.bookedEquipments : [];
    this.bookedPoLines = init?.bookedPoLines ? init.bookedPoLines : [];
  }
}

export class GetBookings {
  results: Booking[];
  pageNumber: number;
  pageCount: number;
  pageSize: number;
  recordCount: number;
  additionalResult: any;

  constructor(init?: Partial<GetBookings>) {
    if (init?.results) this.results = init.results;
    if (init?.pageNumber) this.pageNumber = init.pageNumber;
    if (init?.pageCount) this.pageCount = init.pageCount;
    if (init?.pageSize) this.pageSize = init.pageSize;
    if (init?.recordCount) this.recordCount = init.recordCount;
    if (init?.additionalResult) this.additionalResult = init.additionalResult;
  }
}
