export enum PageOrgMode {
  Lite = 0,
  Client = 1,
  Supplier = 2,
}
export const PAGE_ORG_MODE = PageOrgMode;

export enum UserMode {
  User = 0,
  OrgAdmin = 1,
  NetworkAdmin = 2,
  Clearfreight = 3,
  OmsAdmin = 4,
}
export const USER_MODE = UserMode;

export enum PageInteractionMode {
  Readonly = 0,
  Edit = 1,
  Create = 2,
}
export const PAGE_INTERACTION_MODE = PageInteractionMode;

export enum PurchaseOrderStatus {
  AwaitingClient = 0,
  AwaitingSupplier = 1,
  Rejected = 2,
  Accepted = 3,
  Cancelled = 4,
  Booked = 5,
  ForwarderAssigned = 6
}
export const PURCHASE_ORDER_STATUS = PurchaseOrderStatus;

export enum BookingStatus {
  Unsent = 0,
  AwaitingClient = 1,
  AwaitingSupplier = 2,
  ReadyToShip = 3,
  InTransit = 4,
  Accepted = 5,
  Cancelled = 6,
}
export const BOOKING_STATUS = BookingStatus;

export enum BookingStatusDescription {
  Unsent = 'Unsent',
  AwaitingClient = 'Awaiting Approval',
  AwaitingSupplier = 'Rejected',
  Accepted = 'Approved',
  Cancelled = 'Cancelled',
}

export enum OrgModeText {
  Client = 'Client',
  Supplier = 'Supplier',
}

export enum PurchaseOrderStatusDescription {
  AwaitingClient = 'Awaiting',
  AwaitingSupplier = 'Awaiting Supplier',
  Rejected = 'Rejected',
  Accepted = 'Accepted',
  Cancelled = 'Cancelled',
  Booked = 'Booked',
  ForwarderAssigned = 'Forwarder Assigned'
}

export enum NetworkDenyReasonType {
  Booking = 1,
  PurchaseOrder = 2,
}

export enum TransportMode {
  AIR = 'AIR',
  SEA = 'SEA',
}
