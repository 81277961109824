import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() label = '';
  @Input() set dateValue(value: string) {
    this.date.setValue(value);
  }
  @Input() required = false;
  @Input() extendedWidth = false;
  @Input() appearance = 'outline';
  @Input() marked = false;
  @Input() disabled = false;
  @Output() dateSelected = new EventEmitter<Date>();

  @ViewChild('picker') picker!: MatDatepicker<Date>;

  date = new FormControl('');

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['marked'] && changes['marked'].firstChange === false) {
      this.marked = changes['marked'].currentValue;
      if (this.marked) {
        this.date.markAsTouched();
      }
    }
  }

  ngOnInit(): void {}

  openPicker() {
    this.picker.open();
  }

  onDateChanged(event: MatDatepickerInputEvent<Date>) {
    this.dateSelected.emit(event.value);
  }
}
