import { environment } from 'src/environments/environment';

const PURCHASE_ORDER_URL_FN = (primaryKey: number | string) => `/app/purchase-orders/${primaryKey}`;
const BOOKING_URL_FN = (primaryKey: number | string) => `/app/bookings/${primaryKey}`;
const CONTAINER_URL_FN = (primaryKey: any) => `${environment.clearViewUrl}containers/${primaryKey}`;
const SHIPMENT_URL_FN = (primaryKey: any) => `${environment.clearViewUrl}sea-shipments/${primaryKey}`;
const OMS_SHIPMENT_URL_FN = (primaryKey: number) => `/app/shipments/${primaryKey}`;

const PO_COLUMNS: ITableColumnConfig[] = [
  { name: 'arrow', key: 'arrow', type: 'arrow', enabled: true, onTop: true },
  {
    name: 'PO #',
    key: 'poNumber',
    type: 'hyperlink',
    enabled: true,
    hyperLinkConfig: { urlGrabber: PURCHASE_ORDER_URL_FN, primaryKeyAccessor: 'id' },
    sortKey: 'poNumber',
    alwaysShow: true,
    onTop: true,
  },
  { name: 'status', key: 'poStatus', type: 'poStatus', enabled: true, sortKey: 'poStatus' },
  { name: 'priority', key: 'isPriority', type: 'custom', enabled: true, sortKey: 'isPriority' },
  { name: 'date', key: 'poDate', type: 'date', enabled: true, sortKey: 'poDate' },
  { name: 'type', key: 'poType', type: 'text', enabled: true, sortKey: 'poType' },
  { name: 'alternative PO #', key: 'altPoNumber', type: 'text', enabled: true, sortKey: 'altPoNumber' },
  { name: 'Customer PO #', key: 'customerPoNumber', type: 'text', enabled: true, sortKey: 'customerPoNumber' },
  {
    name: 'expected cargo ready date',
    key: 'expectedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'expectedCargoReadyDate',
  },
  {
    name: 'revised cargo ready date',
    key: 'revisedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'revisedCargoReadyDate',
  },
  {
    name: 'actual cargo ready date',
    key: 'actualCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'actualCargoReadyDate',
  },
  {
    name: 'in distribution center date',
    key: 'inDistCenterDate',
    type: 'date',
    enabled: true,
    sortKey: 'inDistCenterDate',
  },
  { name: 'first ship date', key: 'firstShipDate', type: 'date', enabled: true, sortKey: 'firstShipDate' },
  { name: 'last ship date', key: 'lastShipDate', type: 'date', enabled: true, sortKey: 'lastShipDate' },
  { name: 'book by date', key: 'bookByDate', type: 'date', enabled: true, sortKey: 'bookByDate' },
  {
    name: 'customer start ship date',
    key: 'customerStartShipDate',
    type: 'date',
    enabled: true,
    sortKey: 'customerStartShipDate',
  },
  {
    name: 'customer cancel ship date',
    key: 'customerCancelShipDate',
    type: 'date',
    enabled: true,
    sortKey: 'customerCancelShipDate',
  },
  { name: 'origin country', key: 'originCountry', type: 'text', enabled: true, sortKey: 'originCountry' },
  { name: 'pickup location', key: 'pickupLocation', type: 'location', enabled: true, sortKey: 'pickupLocation.name' },
  { name: 'ship to location', key: 'shipToLocation', type: 'location', enabled: true, sortKey: 'shipToLocation.name' },
  { name: 'transport mode', key: 'transportMode', type: 'text', enabled: true, sortKey: 'transportMode' },
  { name: 'port of loading', key: 'polCode', type: 'text', enabled: true, sortKey: 'polCode' },
  { name: 'port of discharge', key: 'podCode', type: 'text', enabled: true, sortKey: 'podCode' },
  { name: 'incoterm', key: 'incoterm', type: 'text', enabled: true, sortKey: 'incoterm' },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: true, sortKey: 'htsCode' },
  { name: 'supplier', key: 'supplier', type: 'orgName', enabled: true, sortKey: 'supplier.name' },
  { name: 'customer', key: 'customer', type: 'orgName', enabled: true, sortKey: 'customer.name' },
  { name: 'buying agent', key: 'buyingAgent', type: 'orgName', enabled: true, sortKey: 'buyingAgent.name' },
  { name: 'consignee', key: 'consignee', type: 'orgName', enabled: true, sortKey: 'consignee.name' },
];

const PO_LITE_COLUMNS: ITableColumnConfig[] = [
  { name: 'arrow', key: 'arrow', type: 'arrow', enabled: true, onTop: true },
  {
    name: 'PO #',
    key: 'poNumber',
    type: 'hyperlink',
    enabled: true,
    hyperLinkConfig: { urlGrabber: PURCHASE_ORDER_URL_FN, primaryKeyAccessor: 'id' },
    sortKey: 'poNumber',
    alwaysShow: true,
    onTop: true,
  },
  { name: 'status', key: 'poLiteStatus', type: 'poStatus', enabled: true, sortKey: 'poStatus' },
  { name: 'date', key: 'poDate', type: 'date', enabled: true, sortKey: 'poDate' },
  { name: 'type', key: 'poType', type: 'text', enabled: true, sortKey: 'poType' },
  { name: 'alternative PO #', key: 'altPoNumber', type: 'text', enabled: true, sortKey: 'altPoNumber' },
  { name: 'Customer PO #', key: 'customerPoNumber', type: 'text', enabled: true, sortKey: 'customerPoNumber' },
  {
    name: 'expected cargo ready date',
    key: 'expectedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'expectedCargoReadyDate',
  },
  {
    name: 'revised cargo ready date',
    key: 'revisedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'revisedCargoReadyDate',
  },
  {
    name: 'actual cargo ready date',
    key: 'actualCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'actualCargoReadyDate',
  },
  {
    name: 'in distribution center date',
    key: 'inDistCenterDate',
    type: 'date',
    enabled: true,
    sortKey: 'inDistCenterDate',
  },
  { name: 'first ship date', key: 'firstShipDate', type: 'date', enabled: true, sortKey: 'firstShipDate' },
  { name: 'last ship date', key: 'lastShipDate', type: 'date', enabled: true, sortKey: 'lastShipDate' },
  { name: 'book by date', key: 'bookByDate', type: 'date', enabled: true, sortKey: 'bookByDate' },
  {
    name: 'customer start ship date',
    key: 'customerStartShipDate',
    type: 'date',
    enabled: true,
    sortKey: 'customerStartShipDate',
  },
  {
    name: 'customer cancel ship date',
    key: 'customerCancelShipDate',
    type: 'date',
    enabled: true,
    sortKey: 'customerCancelShipDate',
  },
  { name: 'origin country', key: 'originCountry', type: 'text', enabled: true, sortKey: 'originCountry' },
  { name: 'pickup location', key: 'pickupLocation', type: 'location', enabled: true, sortKey: 'pickupLocation.name' },
  { name: 'ship to location', key: 'shipToLocation', type: 'location', enabled: true, sortKey: 'shipToLocation.name' },
  { name: 'transport mode', key: 'transportMode', type: 'text', enabled: true, sortKey: 'transportMode' },
  { name: 'port of loading', key: 'polCode', type: 'text', enabled: true, sortKey: 'polCode' },
  { name: 'port of discharge', key: 'podCode', type: 'text', enabled: true, sortKey: 'podCode' },
  { name: 'incoterm', key: 'incoterm', type: 'text', enabled: true, sortKey: 'incoterm' },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: true, sortKey: 'htsCode' },
  { name: 'supplier', key: 'supplier', type: 'orgName', enabled: true, sortKey: 'supplier.name' },
  { name: 'customer', key: 'customer', type: 'orgName', enabled: true, sortKey: 'customer.name' },
  { name: 'buying agent', key: 'buyingAgent', type: 'orgName', enabled: true, sortKey: 'buyingAgent.name' },
  { name: 'consignee', key: 'consignee', type: 'orgName', enabled: true, sortKey: 'consignee.name' },
];

const PO_LITE_ASSIGNMENT_COLUMNS: ITableColumnConfig[] = [
  { name: 'arrow', key: 'arrow', type: 'arrow', enabled: true, onTop: true },
  {
    name: 'PO #',
    key: 'poNumber',
    type: 'hyperlink',
    enabled: true,
    hyperLinkConfig: { urlGrabber: PURCHASE_ORDER_URL_FN, primaryKeyAccessor: 'id' },
    sortKey: 'poNumber',
    alwaysShow: true,
    onTop: true,
  },
  { name: 'status', key: 'poLiteStatus', type: 'text', enabled: true, sortKey: 'poStatus' },
  { name: 'date', key: 'poDate', type: 'date', enabled: true, sortKey: 'poDate' },
  { name: 'type', key: 'poType', type: 'text', enabled: true, sortKey: 'poType' },
  { name: 'alternative PO #', key: 'altPoNumber', type: 'text', enabled: true, sortKey: 'altPoNumber' },
  { name: 'Customer PO #', key: 'customerPoNumber', type: 'text', enabled: true, sortKey: 'customerPoNumber' },
  {
    name: 'expected cargo ready date',
    key: 'expectedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'expectedCargoReadyDate',
  },
  {
    name: 'revised cargo ready date',
    key: 'revisedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'revisedCargoReadyDate',
  },
  {
    name: 'actual cargo ready date',
    key: 'actualCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'actualCargoReadyDate',
  },
  {
    name: 'in distribution center date',
    key: 'inDistCenterDate',
    type: 'date',
    enabled: true,
    sortKey: 'inDistCenterDate',
  },
  { name: 'first ship date', key: 'firstShipDate', type: 'date', enabled: true, sortKey: 'firstShipDate' },
  { name: 'last ship date', key: 'lastShipDate', type: 'date', enabled: true, sortKey: 'lastShipDate' },
  { name: 'book by date', key: 'bookByDate', type: 'date', enabled: true, sortKey: 'bookByDate' },
  {
    name: 'customer start ship date',
    key: 'customerStartShipDate',
    type: 'date',
    enabled: true,
    sortKey: 'customerStartShipDate',
  },
  {
    name: 'customer cancel ship date',
    key: 'customerCancelShipDate',
    type: 'date',
    enabled: true,
    sortKey: 'customerCancelShipDate',
  },
  { name: 'origin country', key: 'originCountry', type: 'text', enabled: true, sortKey: 'originCountry' },
  { name: 'pickup location', key: 'pickupLocation', type: 'location', enabled: true, sortKey: 'pickupLocation.name' },
  { name: 'ship to location', key: 'shipToLocation', type: 'location', enabled: true, sortKey: 'shipToLocation.name' },
  { name: 'transport mode', key: 'transportMode', type: 'text', enabled: true, sortKey: 'transportMode' },
  { name: 'port of loading', key: 'polCode', type: 'text', enabled: true, sortKey: 'polCode' },
  { name: 'port of discharge', key: 'podCode', type: 'text', enabled: true, sortKey: 'podCode' },
  { name: 'incoterm', key: 'incoterm', type: 'text', enabled: true, sortKey: 'incoterm' },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: true, sortKey: 'htsCode' },
  { name: 'supplier', key: 'supplier', type: 'orgName', enabled: true, sortKey: 'supplier.name' },
  { name: 'customer', key: 'customer', type: 'orgName', enabled: true, sortKey: 'customer.name' },
  { name: 'buying agent', key: 'buyingAgent', type: 'orgName', enabled: true, sortKey: 'buyingAgent.name' },
  { name: 'consignee', key: 'consignee', type: 'orgName', enabled: true, sortKey: 'consignee.name' },
];

const PO_LINE_COLUMNS: ITableColumnConfig[] = [
  { name: 'line #', key: 'lineNumber', type: 'number', enabled: true },
  // { name: 'sub-line #', key: 'subLineNumber', type: 'number', enabled: true },
  { name: 'sku/item #', key: 'skuItemNumber', type: 'text', enabled: true },
  { name: 'description', key: 'productDescription', type: 'text', enabled: true },
  { name: 'booking quantity', key: 'bookingQuantity', type: 'number', enabled: true },
  { name: 'order quantity', key: 'orderQuantity', type: 'number', enabled: true },
  { name: 'balance quantity', key: 'balanceQuantity', type: 'number', enabled: true },
  { name: 'shipped quantity', key: 'shippedQuantity', type: 'text', enabled: true },
  { name: 'quantity unit', key: 'quantityUnitText', type: 'number', enabled: true },
  { name: 'special instructions', key: 'specialInstructions', type: 'text', enabled: true },
  { name: 'item price', key: 'itemPrice', type: 'number', enabled: true },
  { name: 'unit cost', key: 'unitCost', type: 'number', enabled: true },
  { name: 'origin', key: 'origin', type: 'text', enabled: true },
  { name: 'manufacturer', key: 'manufacturer', type: 'orgName', enabled: true },
  { name: 'manufacturer address', key: 'manufacturerLocation', type: 'location', enabled: true },
  { name: 'gross weight', key: 'grossWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'net weight', key: 'netWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'height', key: 'height', unitKey: 'heightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'length', key: 'length', unitKey: 'lengthUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'width', key: 'width', unitKey: 'widthUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'volume', key: 'volume', unitKey: 'volumeUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'size', key: 'size', type: 'text', enabled: true },
  { name: 'color', key: 'color', type: 'text', enabled: true },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: true },
  { name: 'duty', key: 'duty', type: 'text', enabled: true },
  { name: 'Dangerous Goods', key: 'isDg', type: 'bool', enabled: true },
  { name: 'DG class', key: 'dgClass', type: 'text', enabled: true },
  { name: 'DG weight', key: 'dgWeight', unitKey: 'dgUwText', type: 'numberWithUnit', enabled: true },
];

const ASSIGNMENT_PO_LINE_COLUMNS: ITableColumnConfig[] = [
  { name: 'line #', key: 'lineNumber', type: 'number', enabled: true },
  { name: 'sku/item #', key: 'skuItemNumber', type: 'text', enabled: true },
  { name: 'description', key: 'productDescription', type: 'text', enabled: true },
  { name: 'order quantity', key: 'orderQuantity', type: 'number', enabled: true },
  { name: 'balance quantity', key: 'balanceQuantity', type: 'number', enabled: true },
  { name: 'shipped quantity', key: 'shippedQuantity', type: 'text', enabled: true },
  { name: 'committed quantity', key: 'committedQuantity', type: 'text', enabled: true },
  { name: 'quantity unit', key: 'quantityUnitText', type: 'number', enabled: true },
  { name: 'special instructions', key: 'specialInstructions', type: 'text', enabled: true },
  { name: 'item price', key: 'itemPrice', type: 'number', enabled: true },
  { name: 'unit cost', key: 'unitCost', type: 'number', enabled: true },
  { name: 'origin', key: 'origin', type: 'text', enabled: true },
  { name: 'manufacturer', key: 'manufacturer', type: 'orgName', enabled: true },
  { name: 'manufacturer address', key: 'manufacturerLocation', type: 'location', enabled: true },
  { name: 'gross weight', key: 'grossWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'net weight', key: 'netWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'height', key: 'height', unitKey: 'heightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'length', key: 'length', unitKey: 'lengthUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'width', key: 'width', unitKey: 'widthUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'volume', key: 'volume', unitKey: 'volumeUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'size', key: 'size', type: 'text', enabled: true },
  { name: 'color', key: 'color', type: 'text', enabled: true },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: true },
  { name: 'duty', key: 'duty', type: 'text', enabled: true },
  { name: 'Dangerous Goods', key: 'isDg', type: 'bool', enabled: true },
  { name: 'DG class', key: 'dgClass', type: 'text', enabled: true },
  { name: 'DG weight', key: 'dgWeight', unitKey: 'dgUwText', type: 'numberWithUnit', enabled: true },
];

const BOOKING_COLUMNS: ITableColumnConfig[] = [
  {
    name: 'booking #',
    key: 'bookingNumber',
    type: 'hyperlink',
    enabled: true,
    hyperLinkConfig: { urlGrabber: BOOKING_URL_FN, primaryKeyAccessor: 'id' },
    sortKey: 'bookingNumber',
    alwaysShow: true,
    onTop: true,
  },
  { name: 'booking date', key: 'bookingDate', type: 'date', enabled: true, sortKey: 'bookingDate' },
  { name: 'booking status', key: 'bookingStatus', type: 'bookingStatus', enabled: true, sortKey: 'bookingDate' },
  { name: 'priority', key: 'isPriority', type: 'custom', enabled: true, sortKey: 'isPriority' },
  { name: 'shipper', key: 'shipper', type: 'orgName', enabled: true, sortKey: 'shipper.name' },
  {
    name: 'shipper location',
    key: 'shipperLocation',
    type: 'location',
    enabled: true,
    sortKey: 'shipperLocation.name',
  },
  { name: 'consignee', key: 'consignee', type: 'orgName', enabled: true, sortKey: 'consignee.name' },
  {
    name: 'consignee location',
    key: 'consigneeLocation',
    type: 'location',
    enabled: true,
    sortKey: 'consigneeLocation.name',
  },
  { name: 'incoterm', key: 'incoterm', type: 'text', enabled: true, sortKey: 'incoterm' },
  { name: 'cargo ready date', key: 'cargoReadyDate', type: 'date', enabled: true, sortKey: 'cargoReadyDate' },
  {
    name: 'revised cargo ready date',
    key: 'revisedCargoReadyDate',
    type: 'date',
    enabled: true,
    sortKey: 'revisedCargoReadyDate',
  },
  {
    name: 'estimated delivery date',
    key: 'estimatedDeliveryDate',
    type: 'date',
    enabled: true,
    sortKey: 'estimatedDeliveryDate',
  },
  { name: 'port of loading', key: 'portOfLoading', type: 'text', enabled: true, sortKey: 'portOfLoading' },
  { name: 'port of discharge', key: 'portOfDischarge', type: 'text', enabled: true, sortKey: 'portOfDischarge' },
  { name: 'pickup location', key: 'pickupLocation', type: 'location', enabled: true, sortKey: 'pickupLocation.name' },
  { name: 'ship to location', key: 'shipToLocation', type: 'location', enabled: true, sortKey: 'shipToLocation.name' },
  { name: 'transport mode', key: 'transportMode', type: 'text', enabled: true, sortKey: 'transportMode' },
  { name: 'is containerized', key: 'isContainerized', type: 'bool', enabled: true, sortKey: 'isContainerized' },
  { name: 'CW #', key: 'cwBookingNumber', type: 'text', enabled: true, sortKey: 'cwBookingNumber' },
];

const BOOKING_BASIC_PO_COLUMNS: ITableColumnConfig[] = [
  { name: 'PO date', key: 'poDate', type: 'date', enabled: false },
  { name: 'type', key: 'poType', type: 'text', enabled: false },
  { name: 'alternative PO #', key: 'altPoNumber', type: 'text', enabled: false },
  { name: 'custom PO #', key: 'customPoNumber', type: 'text', enabled: false },
  { name: 'expected cargo ready date', key: 'expectedCargoReadyDate', type: 'date', enabled: true },
  { name: 'in distribution center', key: 'inDistCenterDate', type: 'date', enabled: true },
  { name: 'first ship date', key: 'firstShipDate', type: 'date', enabled: true },
  { name: 'last ship date', key: 'lastShipDate', type: 'date', enabled: true },
  { name: 'book by date', key: 'bookByDate', type: 'date', enabled: true },
  { name: 'customer start ship date', key: 'customerStartShipDate', type: 'date', enabled: false },
  { name: 'customer cancel ship date', key: 'customerCancelShipDate', type: 'date', enabled: false },
  { name: 'origin country', key: 'originCountry', type: 'text', enabled: true },
  { name: 'pickup location', key: 'pickupLocation', type: 'location', enabled: true },
  { name: 'ship to location', key: 'shipToLocation', type: 'location', enabled: true },
  { name: 'port of loading', key: 'polCode', type: 'text', enabled: true },
  { name: 'port of discharge', key: 'podCode', type: 'text', enabled: true },
  { name: 'incoterm', key: 'incoterm', type: 'text', enabled: false },
  { name: 'supplier', key: 'supplier', type: 'orgName', enabled: true },
  { name: 'consignee', key: 'consignee', type: 'orgName', enabled: true },
];

const BOOKING_PO_COLUMNS: ITableColumnConfig[] = [
  { name: 'arrow', key: 'arrow', type: 'arrow', enabled: true, onTop: true },
  { name: 'PO #', key: 'poNumber', type: 'text', enabled: true },
  ...BOOKING_BASIC_PO_COLUMNS,
];

const BOOKING_DISPLAY_PO_COLUMNS: ITableColumnConfig[] = [
  { name: 'arrow', key: 'arrow', type: 'arrow', enabled: true, onTop: true },
  {
    name: 'PO #',
    key: 'poNumber',
    type: 'externalHyperlink',
    enabled: true,
    hyperLinkConfig: { urlGrabber: PURCHASE_ORDER_URL_FN, primaryKeyAccessor: 'id' },
    alwaysShow: true,
    onTop: true,
  },
  ...BOOKING_BASIC_PO_COLUMNS,
];

const BOOKING_PO_LINE_COLUMNS: ITableColumnConfig[] = [
  { name: 'line #', key: 'lineNumber', type: 'number', enabled: true },
  { name: 'sku/item #', key: 'skuItemNumber', type: 'text', enabled: true },
  { name: 'description', key: 'description', type: 'text', enabled: true },
  // { name: 'inner packs', key: 'innerPacks', type: 'number', enabled: false },
  // { name: 'total inner packs', key: 'totalInnerPacks', type: 'number', enabled: true },
  // { name: 'outer packs', key: 'outerPacks', type: 'number', enabled: true },
  { name: 'order quantity', key: 'orderQuantity', type: 'number', enabled: true },
  { name: 'committed quantity', key: 'committedQuantity', type: 'number', enabled: true },
  { name: 'balance quantity', key: 'balanceQuantity', type: 'number', enabled: true },
  { name: 'quantity unit', key: 'quantityUnitText', type: 'text', enabled: true },
  { name: 'item price', key: 'itemPrice', type: 'number', enabled: true },
  { name: 'unit cost', key: 'unitCost', type: 'number', enabled: false },
  { name: 'gross weight', key: 'grossWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: false },
  { name: 'net weight', key: 'netWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: false },
  { name: 'height', key: 'height', unitKey: 'heightUnitText', type: 'numberWithUnit', enabled: false },
  { name: 'length', key: 'length', unitKey: 'lengthUnitText', type: 'numberWithUnit', enabled: false },
  { name: 'width', key: 'width', unitKey: 'widthUnitText', type: 'numberWithUnit', enabled: false },
  { name: 'volume', key: 'volume', unitKey: 'volumeUnitText', type: 'numberWithUnit', enabled: false },
  { name: 'size', key: 'size', type: 'text', enabled: false },
  { name: 'color', key: 'color', type: 'text', enabled: false },
  { name: 'DG class', key: 'dgClass', type: 'text', enabled: false },
  { name: 'DG weight', key: 'dgWeight', unitKey: 'dgUwText', type: 'numberWithUnit', enabled: false },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: false },
  { name: 'special instructions', key: 'specialInstructions', type: 'text', enabled: false },
  { name: 'duty', key: 'duty', type: 'text', enabled: false },
  { name: 'origin', key: 'origin', type: 'text', enabled: true },
  { name: 'manufacturer', key: 'manufacturer', type: 'orgName', enabled: true },
];

const PACKOUT_BOOKED_LINE_COLUMNS: ITableColumnConfig[] = [
  { name: 'line #', key: 'lineNumber', type: 'number', enabled: true },
  { name: 'sku/item #', key: 'skuItemNumber', type: 'text', enabled: true },
  { name: 'po Number', key: 'poNumber', type: 'text', enabled: true },
  { name: 'description', key: 'description', type: 'text', enabled: false },
  { name: 'total inner packs', key: 'totalInnerPacks', type: 'number', enabled: false },
  { name: 'outer packs', key: 'outerPacks', type: 'number', enabled: false },
  { name: 'order quantity', key: 'orderQuantity', type: 'number', enabled: false },
  { name: 'quantity unit', key: 'quantityUnitText', type: 'text', enabled: true },
  { name: 'item price', key: 'itemPrice', type: 'number', enabled: true },
  { name: 'unit cost', key: 'unitCost', type: 'number', enabled: false },
  { name: 'gross weight', key: 'grossWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'net weight', key: 'netWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'height', key: 'height', unitKey: 'heightUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'length', key: 'length', unitKey: 'lengthUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'width', key: 'width', unitKey: 'widthUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'volume', key: 'volume', unitKey: 'volumeUnitText', type: 'numberWithUnit', enabled: true },
  { name: 'size', key: 'size', type: 'text', enabled: false },
  { name: 'color', key: 'color', type: 'text', enabled: false },
  { name: 'DG class', key: 'dgClass', type: 'text', enabled: false },
  { name: 'DG weight', key: 'dgWeight', unitKey: 'dgUwText', type: 'numberWithUnit', enabled: false },
  { name: 'HTS code', key: 'htsCode', type: 'text', enabled: false },
  { name: 'duty', key: 'duty', type: 'text', enabled: false },
  { name: 'special instructions', key: 'specialInstructions', type: 'text', enabled: false },
  { name: 'ordered quantity', key: 'bookedQuantity', type: 'text', enabled: true },
];

const ROUTING_LEG_COLUMNS: ITableColumnConfig[] = [
  { name: 'confirm date', key: 'confirmationDate', type: 'date', enabled: true },
  { name: 'move type', key: 'moveType', type: 'text', enabled: true },
  { name: 'transport mode', key: 'transportMode', type: 'text', enabled: true },
  { name: 'carrier', key: 'carrier', type: 'text', enabled: true },
  { name: 'carrier confirmation #', key: 'carrierConfirmationNumber', type: 'text', enabled: true },
  { name: 'contract type', key: 'contractType', type: 'text', enabled: true },
  { name: 'contract number', key: 'contractNumber', type: 'text', enabled: true },
  { name: 'cto cuttoff', key: 'ctoCuttoff', type: 'date', enabled: true },
  { name: 'cto received', key: 'ctoReceived', type: 'date', enabled: true },
  { name: 'cfs cutoff', key: 'cfsCutoff', type: 'date', enabled: true },
  { name: 'cfs received', key: 'cfsReceived', type: 'date', enabled: true },
  { name: 'vgm cutoff', key: 'vgmCutoff', type: 'date', enabled: true },
  { name: 'cto available', key: 'ctoAvailable', type: 'date', enabled: true },
  { name: 'cto storage', key: 'ctoStorage', type: 'date', enabled: true },
  { name: 'cfs available', key: 'cfsAvailable', type: 'date', enabled: true },
  { name: 'cfs storage', key: 'cfsStorage', type: 'date', enabled: true },
  { name: 'vessel', key: 'vessel', type: 'text', enabled: true },
  { name: 'voyage', key: 'voyage', type: 'text', enabled: true },
  { name: 'pol', key: 'pol', type: 'text', enabled: true },
  { name: 'pod', key: 'pod', type: 'text', enabled: true },
  { name: 'etd', key: 'etd', type: 'date', enabled: true },
  { name: 'atd', key: 'atd', type: 'date', enabled: true },
  { name: 'eta', key: 'eta', type: 'date', enabled: true },
  { name: 'ata', key: 'ata', type: 'date', enabled: true },
  { name: 'departure org', key: 'departureOrg', type: 'orgName', enabled: true },
  { name: 'arrival org', key: 'arrivalOrg', type: 'orgName', enabled: true },
  { name: 'pickup location', key: 'pickupLocation', type: 'location', enabled: true },
  { name: 'destination location', key: 'destinationLocation', type: 'location', enabled: true },
];

const CONTAINER_PACKOUT_COLUMNS: ITableColumnConfig[] = [
  {
    name: 'Container Number',
    key: 'containerNumber',
    type: 'text',
    enabled: true,
  },
  { name: 'Container Type', key: 'containerTypeCode', type: 'text', enabled: true },
  { name: 'Seal Numbers', key: 'sealNumbers', type: 'text', enabled: true },
  { name: 'Package Weight', key: 'packedWeight', type: 'custom', enabled: true },
  { name: 'Remaining Weight', key: 'remainingWeight', type: 'custom', enabled: true },
  { name: 'Max Volume', key: 'maxVolume', type: 'custom', enabled: true },
  { name: 'Remaining Volume', key: 'remainingVolume', type: 'custom', enabled: true },
];

const SHIPMENT_COLUMNS: ITableColumnConfig[] = [
  { name: 'Order Refs', key: 'Order Refs', type: 'custom', enabled: true },
  { name: 'Priority', key: 'Priority', type: 'custom', enabled: true },
  { name: 'Shipment Number', key: 'Shipment Number', type: 'custom', enabled: true },
  { name: 'Status', key: 'Status', type: 'custom', enabled: true },
  { name: 'ETD', key: 'ETD', type: 'custom', enabled: true },
  { name: 'Origin/Dest', key: 'Origin/Dest', type: 'custom', enabled: true },
  { name: 'ETA', key: 'ETA', type: 'custom', enabled: true },
  { name: 'Container Number', key: 'Container Number', type: 'custom', enabled: true },
  { name: 'House Bill', key: 'House Bill', type: 'custom', enabled: true },
  { name: 'Shippers Ref', key: 'Shippers Ref', type: 'custom', enabled: true },
  { name: 'Shipper', key: 'Shipper', type: 'custom', enabled: true },
  { name: 'Consignee', key: 'Consignee', type: 'custom', enabled: true },
  { name: 'Total Volume', key: 'Total Volume', type: 'custom', enabled: true },
  { name: 'Total Weight', key: 'Total Weight', type: 'custom', enabled: true },
  { name: 'Total Packages', key: 'Total Packages', type: 'custom', enabled: true },
  { name: 'Estimated Full Delivery', key: 'Estimated Full Delivery', type: 'custom', enabled: true },
  { name: 'Actual Full Delivery', key: 'Actual Full Delivery', type: 'custom', enabled: true },
  { name: 'Chargeable', key: 'Chargeable', type: 'custom', enabled: true },
];

const PO_COLUMNS_WITHOUT = (omitKeys: string[]): ITableColumnConfig[] => {
  return PO_COLUMNS.filter(c => !omitKeys.includes(c.key));
};

const PO_LINE_COLUMNS_WITHOUT = (omitKeys: string[]): ITableColumnConfig[] => {
  return PO_LINE_COLUMNS.filter(c => !omitKeys.includes(c.key));
};

const PO_LITE_COLUMNS_WITHOUT = (omitKeys: string[]): ITableColumnConfig[] => {
  return PO_LITE_COLUMNS.filter(c => !omitKeys.includes(c.key));
};

const ASSIGNMENT_LINE_COLUMNS_WITHOUT = (omitKeys: string[]): ITableColumnConfig[] => {
  return ASSIGNMENT_PO_LINE_COLUMNS.filter(c => !omitKeys.includes(c.key));
};

const BOOKING_COLUMNS_WITHOUT = (omitKeys: string[]): ITableColumnConfig[] => {
  return BOOKING_COLUMNS.filter(c => !omitKeys.includes(c.key));
};

const BOOKING_PO_LINE_COLUMNS_WITHOUT = (omitKeys: string[]): ITableColumnConfig[] => {
  return BOOKING_PO_LINE_COLUMNS.filter(c => !omitKeys.includes(c.key));
};

export interface IHyperlinkColumnConfig {
  urlGrabber(primaryKey: number | string): string;
  primaryKeyAccessor: string;
}

export interface IRelatedObjConfig {
  idKey: string;
  textKey: string;
  elementKey: string;
  background?: string;
  width?: string;
}

export type ColumnType =
  | 'text'
  | 'date'
  | 'number'
  | 'numberWithUnit'
  | 'location'
  | 'orgName'
  | 'hyperlink'
  | 'externalHyperlink'
  | 'bookingStatus'
  | 'poStatus'
  | 'custom'
  | 'bool'
  | 'actionBtn'
  | 'colorText'
  | 'relatedObj'
  | 'arrow';

export interface ITableColumnConfig {
  name: string;
  key: string;
  unitKey?: string;
  suffix?: string;
  type: ColumnType;
  enabled: boolean;
  hyperLinkConfig?: IHyperlinkColumnConfig;
  relatedObjConfig?: IRelatedObjConfig;
  sortKey?: string;
  alwaysShow?: boolean;
  onTop?: boolean;
  onBottom?: boolean;
}

export interface ITableConfiguration {
  columns: ITableColumnConfig[];
}

export const TABLE_CONFIGURATIONS: { [key: string]: ITableConfiguration } = {
  'lite-po-table': {
    columns: [
      ...PO_LITE_COLUMNS.slice(0),
      { name: 'shipment #', key: 'shipmentNumber', type: 'text', enabled: true },
      { name: 'container #', key: 'containerNumber', type: 'text', enabled: true },
    ],
  },
  'product-table': {
    columns: [
      { name: 'sku/item #', key: 'skuItemNumber', type: 'text', enabled: true },
      { name: 'description', key: 'description', type: 'text', enabled: true },
      { name: 'manufacturer', key: 'manufacturersText', type: 'text', enabled: true },
      { name: 'quantity unit', key: 'quantityUnitText', type: 'text', enabled: true },
      { name: 'item price', key: 'itemPrice', type: 'text', enabled: true },
      { name: 'unit cost', key: 'unitCost', type: 'text', enabled: true },
      { name: 'origin', key: 'originText', type: 'text', enabled: true },
      { name: 'gross weight', key: 'grossWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
      { name: 'net weight', key: 'netWeight', unitKey: 'weightUnitText', type: 'numberWithUnit', enabled: true },
      { name: 'height', key: 'height', unitKey: 'heightUnitText', type: 'numberWithUnit', enabled: true },
      { name: 'length', key: 'length', unitKey: 'lengthUnitText', type: 'numberWithUnit', enabled: true },
      { name: 'width', key: 'width', unitKey: 'widthUnitText', type: 'numberWithUnit', enabled: true },
      { name: 'volume', key: 'volume', unitKey: 'volumeUnitText', type: 'numberWithUnit', enabled: true },
      { name: 'size', key: 'size', type: 'text', enabled: true },
      { name: 'color', key: 'color', type: 'text', enabled: true },
      { name: 'Is Danger', key: 'isDg', type: 'bool', enabled: true },
      { name: 'DG class', key: 'dgClass', type: 'text', enabled: true },
      { name: 'DG weight', key: 'dgWeight', unitKey: 'dgUwText', type: 'numberWithUnit', enabled: true },
      { name: 'HTS code', key: 'htsCode', type: 'text', enabled: true },
      { name: 'duty', key: 'duty', type: 'text', enabled: true },
      { name: 'special instructions', key: 'specialInstructions', type: 'text', enabled: true },
      { name: 'Required Inspection', key: 'requiredInspection', type: 'bool', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true, alwaysShow: true, onBottom: true },
    ],
  },
  'lite-po-line-table': {
    columns: [...ASSIGNMENT_LINE_COLUMNS_WITHOUT(['committedQuantity'])],
  },
  'client-po-table': {
    columns: [
      ...PO_COLUMNS.slice(0),
      {
        name: 'booking refs',
        key: 'relatedBookings',
        type: 'relatedObj',
        relatedObjConfig: { idKey: 'key', textKey: 'value', elementKey: 'relatedBookings' },
        hyperLinkConfig: { urlGrabber: BOOKING_URL_FN, primaryKeyAccessor: 'id' },
        enabled: true,
      },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true, alwaysShow: true, onBottom: true },
    ],
  },
  'client-po-line-table': {
    columns: [...PO_LINE_COLUMNS.slice(0)],
  },
  'supplier-po-table': {
    columns: [
      ...PO_COLUMNS.slice(0),
      {
        name: 'booking refs',
        key: 'relatedBookings',
        type: 'relatedObj',
        relatedObjConfig: { idKey: 'key', textKey: 'value', elementKey: 'relatedBookings' },
        hyperLinkConfig: { urlGrabber: BOOKING_URL_FN, primaryKeyAccessor: 'id' },
        enabled: true,
      },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true, alwaysShow: true, onBottom: true },
    ],
  },
  'supplier-po-line-table': {
    columns: [...PO_LINE_COLUMNS.slice(0)],
  },
  'job-equipment-table': {
    columns: [
      { name: 'type', key: 'equipmentType', type: 'text', enabled: true },
      { name: 'quantity', key: 'quantity', type: 'number', enabled: true },
    ],
  },
  'job-equipment-editor-table': {
    columns: [
      { name: 'type', key: 'equipmentType', type: 'custom', enabled: true },
      { name: 'quantity', key: 'quantity', type: 'custom', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true, alwaysShow: true },
    ],
  },
  'file-browser-table': {
    columns: [
      { name: 'checkbox', key: 'checkbox', type: 'custom', enabled: true, onTop: true },
      { name: 'type', key: 'documentType', type: 'custom', enabled: true },
      { name: 'status', key: 'status', type: 'custom', enabled: true },
      { name: 'user', key: 'userName', type: 'text', enabled: true },
      { name: 'date', key: 'receivedDate', type: 'date', enabled: true },
      { name: 'name', key: 'filename', type: 'custom', enabled: true },
    ],
  },
  'client-booking-browser-table': {
    columns: [
      ...BOOKING_COLUMNS.slice(0),
      {
        name: 'purchase order refs',
        key: 'bookedPurchaseOrders',
        type: 'relatedObj',
        relatedObjConfig: { idKey: 'id', textKey: 'poNumber', elementKey: 'bookedPurchaseOrders' },
        hyperLinkConfig: { urlGrabber: PURCHASE_ORDER_URL_FN, primaryKeyAccessor: 'id' },
        enabled: true,
      },
      // {name: 'actions', key: 'actions', type: 'custom', enabled: true}
    ],
  },
  'supplier-booking-browser-table': {
    columns: [
      ...BOOKING_COLUMNS.slice(0),
      {
        name: 'purchase order refs',
        key: 'bookedPurchaseOrders',
        type: 'relatedObj',
        relatedObjConfig: { idKey: 'id', textKey: 'poNumber', elementKey: 'bookedPurchaseOrders' },
        hyperLinkConfig: { urlGrabber: PURCHASE_ORDER_URL_FN, primaryKeyAccessor: 'id' },
        enabled: true,
      },
      // {name: 'actions', key: 'actions', type: 'custom', enabled: true}
    ],
  },
  'job-po-table': {
    columns: [...BOOKING_DISPLAY_PO_COLUMNS.slice(0)],
  },
  'job-display-po-line-table': {
    columns: [
      ...BOOKING_PO_LINE_COLUMNS.slice(0),
      { name: 'booking quantity', key: 'bookingQuantityDisplay', type: 'custom', enabled: true },
    ],
  },
  'job-po-selector-table': {
    columns: [...BOOKING_PO_COLUMNS.slice(0), { name: 'actions', key: 'actions', type: 'actionBtn', enabled: true }],
  },
  'job-po-line-selector-table': {
    columns: [
      ...BOOKING_PO_LINE_COLUMNS.slice(0),
      {
        name: 'booked refs',
        key: 'bookedQuanties',
        type: 'relatedObj',
        relatedObjConfig: {
          idKey: 'id',
          textKey: 'internalNumber',
          elementKey: 'bookedQuanties',
          background: 'none',
          width: '400px',
        },
        hyperLinkConfig: { urlGrabber: BOOKING_URL_FN, primaryKeyAccessor: 'id' },
        enabled: true,
      },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'job-selected-po-table': {
    columns: [...BOOKING_PO_COLUMNS.slice(0)],
  },
  'job-selected-po-line-table': {
    columns: [
      ...BOOKING_PO_LINE_COLUMNS.slice(0),
      {
        name: 'booked refs',
        key: 'bookedQuanties',
        type: 'relatedObj',
        relatedObjConfig: {
          idKey: 'id',
          textKey: 'internalNumber',
          elementKey: 'bookedQuanties',
          background: 'none',
          width: '400px',
        },
        hyperLinkConfig: { urlGrabber: BOOKING_URL_FN, primaryKeyAccessor: 'id' },
        enabled: true,
      },
      { name: 'booking quantity', key: 'bookingQuantity', type: 'custom', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'container-packout-table': {
    columns: [
      ...CONTAINER_PACKOUT_COLUMNS.slice(0),
      { name: 'Operation', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'container-packout-display-table': {
    columns: [...CONTAINER_PACKOUT_COLUMNS.slice(0)],
  },

  'container-packout-assigned-booked-line-table': {
    columns: [
      ...PACKOUT_BOOKED_LINE_COLUMNS.slice(0),
      // .splice(0, 0,
      //   { name: 'balance quantity', key: 'balanceQuantity', type: 'number', enabled: true },
      //   { name: 'packing quantity', key: 'packingQuantity', type: 'custom', enabled: true }
      // ),
      { name: 'remaining quantity', key: 'balanceQuantity', type: 'custom', enabled: true },
      { name: 'packing quantity', key: 'packingQuantity', type: 'custom', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },

  'container-packout-unassigned-booked-line-table': {
    columns: [
      ...PACKOUT_BOOKED_LINE_COLUMNS.slice(0),
      { name: 'total packed quantity', key: 'committedQuantity', type: 'text', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },

  'networks-table': {
    columns: [
      { name: 'customer name', key: 'name', type: 'custom', enabled: true },
      { name: 'customer code', key: 'code', type: 'text', enabled: true },
      { name: 'enabled', key: 'networkEnabled', type: 'custom', enabled: true },
      // {name: 'actions', key: 'actions', type: 'custom', enabled: true},
    ],
  },
  'network-org-table': {
    columns: [
      { name: 'organization name', key: 'name', type: 'text', enabled: true },
      { name: 'organization code', key: 'code', type: 'text', enabled: true },
      { name: 'organization type', key: 'type', type: 'text', enabled: true },
      { name: 'active', key: 'enabled', type: 'custom', enabled: true },
    ],
  },
  'in-transit-table': {
    columns: [...BOOKING_COLUMNS_WITHOUT(['isPriority'])],
  },
  'user-management-network-table': {
    columns: [
      { name: 'name', key: 'name', type: 'text', enabled: true },
      { name: 'code', key: 'code', type: 'text', enabled: true },
    ],
  },
  'user-management-user-table': {
    columns: [
      { name: 'first name', key: 'firstName', type: 'text', enabled: true },
      { name: 'last name', key: 'lastName', type: 'text', enabled: true },
      { name: 'email', key: 'email', type: 'text', enabled: true },
      { name: 'phone number', key: 'phoneNumber', type: 'text', enabled: true },
      { name: 'network admin', key: 'isNetworkAdmin', type: 'custom', enabled: true },
      //{name: 'is clearfreight', key: 'isClearfreight', type: 'custom', enabled: true},
      { name: 'organization', key: 'organization', type: 'custom', enabled: true },
      { name: 'active', key: 'active', type: 'custom', enabled: true },
      { name: 'enabled', key: 'enabled', type: 'custom', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'notification-settings-table': {
    columns: [
      { name: 'type', key: 'description', type: 'custom', enabled: true },
      { name: 'email', key: 'email', type: 'custom', enabled: true },
      { name: 'SMS', key: 'sms', type: 'custom', enabled: true },
      { name: 'internal message', key: 'internalMessage', type: 'custom', enabled: true },
    ],
  },
  'po-assignment-po-table': {
    columns: [...PO_LITE_COLUMNS.slice(0), { name: 'actions', key: 'actions', type: 'custom', enabled: true }],
  },
  'po-assignment-po-line-table': {
    columns: [
      ...ASSIGNMENT_LINE_COLUMNS_WITHOUT(['shippedQuantity']),
      { name: 'shipped quantity', key: 'shippedQuantity', type: 'custom', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'po-unassignment-po-table': {
    columns: [...PO_LITE_COLUMNS.slice(0), { name: 'actions', key: 'actions', type: 'custom', enabled: true }],
  },
  'po-unassignment-po-line-table': {
    columns: [
      ...ASSIGNMENT_LINE_COLUMNS_WITHOUT(['shippedQuantity']),
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'booking-popover-table': {
    columns: [...BOOKING_COLUMNS_WITHOUT(['isPriority'])],
  },
  'routing-leg-table': {
    columns: [...ROUTING_LEG_COLUMNS.slice(0)],
  },
  'job-leg-table': {
    columns: [...ROUTING_LEG_COLUMNS.slice(0)],
  },
  'routing-guide-admin-table': {
    columns: [
      { name: 'port of load', key: 'pol', type: 'text', enabled: true },
      { name: 'port of discharge', key: 'pod', type: 'text', enabled: true },
      { name: 'supplier', key: 'supplier', type: 'orgName', enabled: true },
      { name: 'forwarder', key: 'forwarder', type: 'orgName', enabled: true },
      { name: 'forwarder contact', key: 'contactNames', type: 'text', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'routing-guide-table': {
    columns: [
      { name: 'port of load', key: 'pol', type: 'text', enabled: true },
      { name: 'port of discharge', key: 'pod', type: 'text', enabled: true },
      { name: 'forwarder', key: 'forwarder', type: 'orgName', enabled: true },
      { name: 'forwarder contact', key: 'contactNames', type: 'text', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'container-table2': {
    columns: [
      {
        name: 'container',
        key: 'containerNumber',
        type: 'externalHyperlink',
        enabled: true,
        hyperLinkConfig: { urlGrabber: CONTAINER_URL_FN, primaryKeyAccessor: 'containerPk' },
      },
      {
        name: 'shipment',
        key: 'shipmentNumber',
        type: 'externalHyperlink',
        enabled: true,
        hyperLinkConfig: { urlGrabber: SHIPMENT_URL_FN, primaryKeyAccessor: 'shipmentPk' },
      },
      { name: 'assigned orders', key: 'assignedLineCount', type: 'text', enabled: true },
      { name: 'consignee', key: 'consignee', type: 'text', enabled: true },
      { name: 'container code', key: 'containerCode', type: 'text', enabled: true },
      { name: 'container mode', key: 'containerMode', type: 'text', enabled: true },
      { name: 'eta', key: 'eta', type: 'date', enabled: true },
      { name: 'etd', key: 'etd', type: 'date', enabled: true },
      { name: 'pol', key: 'portOfLoad', type: 'text', enabled: true },
      { name: 'pod', key: 'portOfDischarge', type: 'text', enabled: true },
      { name: 'seal', key: 'sealNumber', type: 'text', enabled: true },
      { name: 'supplier', key: 'supplier', type: 'text', enabled: true },
      { name: 'vessel', key: 'vessel', type: 'text', enabled: true },
      { name: 'voyage', key: 'voyage', type: 'text', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true },
    ],
  },
  'container-purchase-order-table': {
    columns: [...PO_LITE_ASSIGNMENT_COLUMNS.slice(0)],
  },
  'container-purchase-order-line-table': {
    columns: [...ASSIGNMENT_PO_LINE_COLUMNS.slice(0)],
  },
  'sea-shipment-table': {
    columns: [
      ...SHIPMENT_COLUMNS.slice(0),
      { name: 'Master Bill', key: 'Master Bill', type: 'custom', enabled: true },
      { name: 'Origin ETD', key: 'Origin ETD', type: 'custom', enabled: true },
      { name: 'Origin ATD', key: 'Origin ATD', type: 'custom', enabled: true },
      { name: 'Vessel', key: 'Vessel', type: 'custom', enabled: true },
    ],
  },
  'roa-shipment-table': {
    columns: [...SHIPMENT_COLUMNS.slice(0)],
  },
  'rai-shipment-table': {
    columns: [...SHIPMENT_COLUMNS.slice(0)],
  },
  'air-shipment-table': {
    columns: [
      ...SHIPMENT_COLUMNS.slice(0),
      { name: 'Master Air Waybill', key: 'Master Air Waybill', type: 'custom', enabled: true },
    ],
  },

  'container-table': {
    columns: [
      { name: 'Order Refs', key: 'Order Refs', type: 'custom', enabled: true },
      { name: 'Container Number', key: 'Container Number', type: 'custom', enabled: true },
      { name: 'Status', key: 'Status', type: 'custom', enabled: true },
      { name: 'Shipper', key: 'Shipper', type: 'custom', enabled: true },
      { name: 'Consignee', key: 'Consignee', type: 'custom', enabled: true },
      { name: 'ETD', key: 'ETD', type: 'custom', enabled: true },
      { name: 'Origin/Dest', key: 'Origin/Dest', type: 'custom', enabled: true },
      { name: 'ETA', key: 'ETA', type: 'custom', enabled: true },
      { name: 'Master Bill', key: 'Master Bill', type: 'custom', enabled: true },
      { name: 'House Bill', key: 'House Bill', type: 'custom', enabled: true },
      { name: 'Shipment Number', key: 'Shipment Number', type: 'custom', enabled: true },
      { name: 'Shippers Ref', key: 'Shippers Ref', type: 'custom', enabled: true },
      { name: 'Container Type', key: 'Container Type', type: 'custom', enabled: true },
      { name: 'Total Volume', key: 'Total Volume', type: 'custom', enabled: true },
      { name: 'Total Weight', key: 'Total Weight', type: 'custom', enabled: true },
      { name: 'Total Packages', key: 'Total Packages', type: 'custom', enabled: true },
      { name: 'Vessel', key: 'Vessel', type: 'custom', enabled: true },
      { name: 'Origin Gate In Date', key: 'Origin Gate In Date', type: 'custom', enabled: true },
      { name: 'Origin Estimated Departure', key: 'Origin Estimated Departure', type: 'custom', enabled: true },
      { name: 'Onboard', key: 'Onboard', type: 'custom', enabled: true },
      { name: 'Origin Actual Departure', key: 'Origin Actual Departure', type: 'custom', enabled: true },
      { name: 'Estimated Full Delivery', key: 'Estimated Full Delivery', type: 'custom', enabled: true },
      { name: 'Wharf Gate Out', key: 'Wharf Gate Out', type: 'custom', enabled: true },
      { name: 'Actual Full Delivery', key: 'Actual Full Delivery', type: 'custom', enabled: true },
      { name: 'Empty Return Date', key: 'Empty Return Date', type: 'custom', enabled: true },
    ],
  },
  'events-table': {
    columns: [
      { name: 'Icon', key: 'CustomIcon', type: 'custom', enabled: true },
      { name: 'Type', key: 'EventType', type: 'text', enabled: true },
      { name: 'Time', key: 'EventTime', type: 'custom', enabled: true },
      { name: 'Description', key: 'Description', type: 'text', enabled: true },
    ],
  },
  'contact-table': {
    columns: [
      { name: 'name', key: 'name', type: 'text', enabled: true, sortKey: 'name' },
      { name: 'title', key: 'title', type: 'text', enabled: true, sortKey: 'title' },
      { name: 'forwarders', key: 'organizationNames', type: 'text', enabled: true, sortKey: 'organizationNames' },
      { name: 'phone', key: 'phone', type: 'text', enabled: true, sortKey: 'phone' },
      { name: 'extension', key: 'extension', type: 'text', enabled: true, sortKey: 'extension' },
      { name: 'mobile', key: 'mobile', type: 'text', enabled: true, sortKey: 'mobile' },
      { name: 'fax', key: 'fax', type: 'text', enabled: true, sortKey: 'fax' },
      { name: 'email', key: 'email', type: 'text', enabled: true, sortKey: 'email' },
      { name: 'personal info', key: 'personalInfo', type: 'text', enabled: true, sortKey: 'personalInfo' },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true, alwaysShow: true, onBottom: true },
    ],
  },
  'ip-restriction-editor-table': {
    columns: [
      { name: 'Start Ip', key: 'startIp', type: 'custom', enabled: true },
      { name: 'End Ip', key: 'endIp', type: 'custom', enabled: true },
      { name: 'actions', key: 'actions', type: 'custom', enabled: true, alwaysShow: true },
    ],
  },
  'report-table': {
    columns: [
      { name: 'report name', key: 'reportName', type: 'text', enabled: true },
      { name: 'report type', key: 'reportType', type: 'custom', enabled: true },
      { name: 'operation', key: 'operation', type: 'custom', enabled: true },
    ],
  },
};
