<form [formGroup]="form" class="edit-section" style="padding-left: 15px;">
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Transport Mode</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="transportMode">
            <mat-option [value]="null">---</mat-option>
            <mat-option [value]="'AIR'">AIR</mat-option>
            <mat-option [value]="'SEA'">SEA</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Manufacturer</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="manufacturerOrgCode">
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let org of manufacturers" [value]="org.code">{{ org.name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Pickup Location</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="pickupLocationCode">
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let location of pickupLocations" [value]="location.code">{{ location.code }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Incoterm</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="incoterm">
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let option of incoterms" [value]="option.code">
              {{ option.code }} - {{ option.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Consignee</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="consigneeOrgCode">
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let org of consignees" [value]="org.code">
              {{org.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <label class="detail-field-title">Ship To Location</label>
      </div>
      <div class="row detail-field-content">
        <mat-form-field appearance="outline" class="extended-width">
          <mat-select formControlName="shipToLocationCode">
            <mat-option [value]="null">---</mat-option>
            <mat-option *ngFor="let location of shipToLocations" [value]="location.code">
              {{ location.code }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="form-field col-2">
      <div class="row">
        <app-unloco-country label="Origin Country" formControlName="originCountry" [extendedWidth]="true" class="unloco-width"></app-unloco-country>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <app-unloco label="POL" formControlName="pol" [extendedWidth]="true" class="unloco-width"></app-unloco>
      </div>
    </div>
    <div class="form-field col-2">
      <div class="row">
        <app-unloco label="POD" formControlName="pod" [extendedWidth]="true" class="unloco-width"></app-unloco>
      </div>
    </div>
  </div>
</form>
