import { Overlay, OverlayConfig, OverlayContainer, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Alert } from '@models/alert.model';
import { AlertsComponent } from '@modules/alert/views/alerts.component';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface AlertGroupMetadata {
  header: string;
  iconName: string;
}

export interface AlertTypeMetadata {
  header: string;
  group: AlertGroup;
}

// export type AlertType =
//     | 'IMPORT'
//     | 'PO_QUERY'
//     | 'PO_DELETE'
//     | 'PO_STATUS'
//     | 'PRIORITY'
//     | 'UNBOOKED'
//     | 'LATE_SHIP'
//     | 'PO_REMARK'
//     | 'BKN_REMARK'
//     | 'PO_DOC'
//     | 'BKN_DOC';
// ;

export enum AlertType {
  None = 0,
  Import = 1,

  NewPoRemark = 8,
  NewBookingRemark = 9,
  NewPoDocument = 10,
  NewBookingDocument = 11,

  PoPriority = 20,
  PoCreated = 21,
  PoEdited = 22,
  PoRejected = 23,
  PoAccepted = 24,
  PoCancelled = 25,
  PoReactivated = 26,

  BookingPriority = 30,
  BookingCreated = 31,
  BookingEdited = 32,
  BookingRejected = 33,
  BookingAccepted = 34,
  BookingCancelled = 35,
  BookingReactivated = 36,
  BookingEditApproved = 37,
}

export type AlertGroup = 'IMPORT' | 'PO' | 'BOOKING' | 'REMARK';

@Injectable()
export class AlertService {
  private _overlayRef: OverlayRef;
  private _alertFetchInterval: any;
  private _alertsSubject = new BehaviorSubject<Alert[]>([]);
  private _currentAlertGroup: AlertGroup;
  private _outsideEventsSubscription: Subscription;
  private _containerElement: HTMLElement;

  private _alertGroupMetaMap = new Map<AlertGroup, AlertGroupMetadata>([
    ['IMPORT', { header: 'Import Errors', iconName: 'import' }],
    ['PO', { header: 'PO Alerts', iconName: 'clipboard' }],
    ['BOOKING', { header: 'Booking Alerts', iconName: 'menu-book' }],
    ['REMARK', { header: 'New Remarks', iconName: 'comment' }],
  ]);

  private _alertTypeMetaMap = new Map<AlertType, AlertTypeMetadata>([
    [AlertType.Import, { header: 'PO Import', group: 'IMPORT' }],
    [AlertType.NewPoRemark, { header: 'New Purchase Order Remark', group: 'REMARK' }],
    [AlertType.NewBookingRemark, { header: 'New Booking Remark', group: 'REMARK' }],
    [AlertType.NewPoDocument, { header: 'New Purchase Order Document', group: 'PO' }],
    [AlertType.NewBookingDocument, { header: 'New Booking Document', group: 'BOOKING' }],

    [AlertType.PoPriority, { header: 'Priority', group: 'PO' }],
    [AlertType.PoCreated, { header: 'Created', group: 'PO' }],
    [AlertType.PoEdited, { header: 'Edited', group: 'PO' }],
    [AlertType.PoRejected, { header: 'Rejected', group: 'PO' }],
    [AlertType.PoAccepted, { header: 'Accepted', group: 'PO' }],
    [AlertType.PoCancelled, { header: 'Cancelled', group: 'PO' }],
    [AlertType.PoReactivated, { header: 'Reactivated', group: 'PO' }],

    [AlertType.BookingPriority, { header: 'Priority', group: 'BOOKING' }],
    [AlertType.BookingCreated, { header: 'Created', group: 'BOOKING' }],
    [AlertType.BookingEdited, { header: 'Edited', group: 'BOOKING' }],
    [AlertType.BookingRejected, { header: 'Rejected', group: 'BOOKING' }],
    [AlertType.BookingAccepted, { header: 'Accepted', group: 'BOOKING' }],
    [AlertType.BookingCancelled, { header: 'Cancelled', group: 'BOOKING' }],
    [AlertType.BookingReactivated, { header: 'Reactivated', group: 'BOOKING' }],
    [AlertType.BookingEditApproved, { header: 'Edit Approved', group: 'BOOKING' }],
  ]);

  public alerts$ = this._alertsSubject.asObservable();
  public alertInit = false;

  public get currentAlertGroup() {
    return this._currentAlertGroup;
  }

  constructor(private httpClient: HttpClient, private overlay: Overlay, private overlayContainer: OverlayContainer) {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: false,
      disposeOnNavigation: true,
    });
    this._overlayRef = this.overlay.create(overlayConfig);
  }

  public getMetadata(group: AlertGroup): AlertGroupMetadata {
    return this._alertGroupMetaMap.get(group);
  }

  public openAlerts(group: AlertGroup) {
    this._currentAlertGroup = group;

    const componentPortal = new ComponentPortal(AlertsComponent);

    this._containerElement = this.overlayContainer.getContainerElement();

    this._outsideEventsSubscription = this._overlayRef.outsidePointerEvents().subscribe((event: MouseEvent) => {
      const sidebar = document.querySelector('#app-sidebar');
      const upperMenu = document.querySelector('#upper-menu');
      const target = event.target as Node;
      if (sidebar.contains(target) || upperMenu.contains(target)) {
        this.closeAlerts();
      }
    });

    this._containerElement.classList.add('alert-dialog-panel');

    this._overlayRef.attach(componentPortal);
  }

  public closeAlerts() {
    this._containerElement.classList.remove('alert-dialog-panel');
    this._overlayRef.detach();
    this._outsideEventsSubscription.unsubscribe();
  }

  public startAlertFetch() {
    this.getAlertsFromApi();
    this._alertFetchInterval = setInterval(() => {
      this.getAlertsFromApi();
    }, 1000 * 60 * 5); // is 5 minutes fine?
  }

  public endAlertFetch() {
    clearInterval(this._alertFetchInterval);
    this._alertFetchInterval = null;
    this._alertsSubject.next([]);
  }

  public getAlertsFromApi() {
    this.alertInit = true;
    this.httpClient.get<Alert[]>(`${environment.apiBaseURL}Alert`).subscribe(
      res => {
        const alerts = res;
        alerts.forEach(alert => {
          alert.metadata = this._alertTypeMetaMap.get(alert.type);
        });
        this.alertInit = false;
        this._alertsSubject.next(alerts);
      },
      err => {
        this.alertInit = false;
      }
    );
  }

  public getUserAlertGroups(): AlertGroup[] {
    // TODO: return alert groups according to the type of user (admin, supplier, etc.)
    return ['IMPORT', 'PO', 'BOOKING', 'REMARK'];
  }

  public getUserLiteAlertGroups(): AlertGroup[] {
    return ['IMPORT', 'PO', 'REMARK'];
  }

  public markAlert(id: number) {
    this.httpClient.post<Alert[]>(`${environment.apiBaseURL}Alert/${id}/dismiss/`, {}).subscribe(res => {
      const alerts = res;
      alerts.forEach(alert => {
        alert.metadata = this._alertTypeMetaMap.get(alert.type);
      });
      this._alertsSubject.next(alerts);
    });
  }

  public getPendingCount(isLite: boolean): Observable<any> {
    return this.httpClient.get<any>(`${environment.apiBaseURL}alert/getPendingCount?isLite=${isLite}`);
  }
}
