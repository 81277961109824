import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserNetworkApiService } from '@services/user-network-api.service';
import { Organization } from '@models/organization.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from '@models/contact.model';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Unloco } from '@models/unloco.model';

@Component({
  selector: 'app-om-routing-guide-admin',
  styleUrls: ['./routing-guide-editor.component.scss'],
  templateUrl: './routing-guide-editor.component.html',
})
export class RoutingGuideEditorComponent implements OnInit {
  forwarders: Organization[];
  suppliers: Organization[];
  contacts: Contact[];
  showContacts: Contact[];

  form = new UntypedFormGroup({
    id: new UntypedFormControl(null),
    pol: new UntypedFormControl(null, Validators.required),
    pod: new UntypedFormControl(null, Validators.required),
    polTimezone: new UntypedFormControl(null),
    podTimezone: new UntypedFormControl(null),
    supplier: new UntypedFormControl(null, Validators.required),
    contactIds: new UntypedFormControl(null, Validators.required),
    forwarder: new UntypedFormControl(null, Validators.required),
  });

  public loading: boolean = false;

  constructor(
    private userNetworkService: UserNetworkApiService,
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<RoutingGuideEditorComponent>,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.loading = true;
    forkJoin({
      organizations: this.userNetworkService.getOrgsInCurrentNetwork(),
      contacts: this.userNetworkService.getContactsInCurrentNetwork(),
    }).subscribe(
      ({ organizations, contacts }) => {
        this.suppliers = organizations.suppliers;
        this.forwarders = organizations.forwarders;
        this.contacts = contacts;
        this.form.patchValue(this.data.routingGuide);
        if (
          this.data.routingGuide?.id &&
          this.data.routingGuide?.contactIds &&
          this.data.routingGuide?.contactIds?.length > 0
        ) {
          this.showContacts = [
            ...this.contacts.filter(
              c =>
                c.networkId === this.data.routingGuide.networkId &&
                c.organizationIds.indexOf(this.data.routingGuide.forwarder.id) >= 0
            ),
          ];
        }
        this.form.controls.forwarder.valueChanges.subscribe(res => {
          this.showContacts = [
            ...this.contacts.filter(
              c => c.networkId === this.form.get('forwarder').value?.networkId && c.organizationIds.indexOf(res.id) >= 0
            ),
          ];
        });
        this.loading = false;
      },
      _ => {
        this.toastr.error('Error loading required resources for routing guide editor.');
        this.loading = false;
      }
    );
  }

  public cancel() {
    this.dialogRef.close(false);
  }

  public save() {
    // if (!this.form.valid) {
    //   this.toastr.warning('Please fill out all of the required fields.');
    //   return;
    // }

    this.loading = true;
    const routingGuide = this.form.getRawValue();
    this.userNetworkService.saveRoutingGuide(routingGuide).subscribe(
      _ => {
        this.loading = false;
        this.dialogRef.close(true);
      },
      _ => {
        this.toastr.error('Error saving ');
        this.loading = false;
      }
    );
  }

  public selectPOL(item: Unloco): void {
    this.form.get('polTimezone').setValue(item.timezone);
  }

  public selectPOD(item: Unloco): void {
    this.form.get('podTimezone').setValue(item.timezone);
  }

  public compareFn(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.id === o2.id : o1 === o2;
  }

  public compareContactFn(o1: number, o2: number): boolean {
    return o1 && o2 ? o1 === o2 : false;
  }
}
