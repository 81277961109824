<form [formGroup]="form">
  <div class="input-container edit-section">
    <div class="row">
      <!-- <div class="col">
        <div class="form-field">
          <div class="row">
            <label class="detail-field-title">Shipper</label>
          </div>
          <div class="row detail-field-content">
            <mat-form-field appearance="outline" class="extended-width">
              <mat-select formControlName="shipper" [compareWith]="orgCompareFn" required>
                <mat-option *ngFor="let org of shippers" [value]="org">{{ org.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div> -->
      <!-- <div class="col">
        <div class="form-field">
          <div class="row">
            <label class="detail-field-title">Buyer</label>
          </div>
          <div class="row detail-field-content">
            <mat-form-field appearance="outline" class="extended-width">
              <mat-select formControlName="customer" [compareWith]="orgCompareFn" required>
                <mat-option *ngFor="let org of buyers" [value]="org">{{ org.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div> -->
    </div>
    <div class="row">
      <mat-accordion class="oms-accordion" multi>
        <mat-expansion-panel
          [expanded]="panelOpenState0"
          (opened)="changePanelState(0, true)"
          (closed)="changePanelState(0, false)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Main</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Transport Mode</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width" required>
                    <mat-select formControlName="transportMode" required>
                      <mat-option [value]="''"></mat-option>
                      <mat-option *ngFor="let item of Transport_Mode | keyvalue" [value]="item.key">
                        {{ item.value }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-3" *ngIf="form.value.transportMode === Transport_Mode.SEA">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Is Containerized</label>
                </div>
                <div class="row detail-field-content">
                  <mat-checkbox formControlName="isContainerized"></mat-checkbox>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Incoterm</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select formControlName="incoterm">
                      <mat-option>--Select Option--</mat-option>
                      <mat-option *ngFor="let option of incoterms" [value]="option.code">
                        {{ option.code }} - {{ option.description }}
                      </mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.incoterm.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'incoterm')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Goods Description</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field class="extended-width" appearance="outline">
                    <input matInput formControlName="goodDescription" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          [expanded]="panelOpenState1"
          (opened)="changePanelState(1, true)"
          (closed)="changePanelState(1, false)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Related Parties</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Consignee</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select formControlName="consignee" [compareWith]="orgCompareFn" required>
                      <mat-option *ngFor="let org of consignees" [value]="org">{{ org.name }}</mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.consignee.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'consignee')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Consignee Location</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select formControlName="consigneeLocation" [compareWith]="locationCompareFn" required>
                      <mat-option *ngFor="let loc of consigneeLocations" [value]="loc" class="multiline-mat-option">
                        {{ loc | location : 'full' }}
                      </mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.consigneeLocation.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'consigneeLocation')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Ship To</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select matInput type="text" [compareWith]="compareFn" formControlName="shipToLocation">
                      <mat-option
                        *ngFor="let location of consigneeLocations"
                        [value]="location"
                        class="multiline-mat-option"
                      >
                        {{ location | location : 'full' }}
                      </mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.shipToLocation.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'shipToLocation')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Pickup</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select matInput type="text" [compareWith]="compareFn" formControlName="pickupLocation">
                      <mat-option
                        *ngFor="let location of shipperLocations"
                        [value]="location"
                        class="multiline-mat-option"
                      >
                        {{ location | location : 'full' }}
                      </mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.pickupLocation.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'pickupLocation')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Manufacturer</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select formControlName="manufacturer" [compareWith]="orgCompareFn" required>
                      <mat-option *ngFor="let org of manufacturers" [value]="org">{{ org.name }}</mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.manufacturer.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'manufacturer')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Shipper Location</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select formControlName="shipperLocation" [compareWith]="locationCompareFn" required>
                      <mat-option *ngFor="let loc of shipperLocations" [value]="loc" class="multiline-mat-option">
                        {{ loc | location : 'full' }}
                      </mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.shipperLocation.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'shipperLocation')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <!-- <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <label class="detail-field-title">Buyer Location</label>
                </div>
                <div class="row detail-field-content">
                  <mat-form-field appearance="outline" class="extended-width">
                    <mat-select formControlName="customerLocation" [compareWith]="locationCompareFn" required>
                      <mat-option *ngFor="let loc of customerLocations" [value]="loc" class="multiline-mat-option">
                        {{ loc | location : 'full' }}
                      </mat-option>
                    </mat-select>
                    <mat-icon
                      *ngIf="form.controls.customerLocation.value"
                      matSuffix
                      class="icon-action-red"
                      (click)="clearField($event, 'customerLocation')"
                    >
                      delete
                    </mat-icon>
                  </mat-form-field>
                </div>
              </div>
            </div> -->
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
          [expanded]="panelOpenState2"
          (opened)="changePanelState(2, true)"
          (closed)="changePanelState(2, false)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title>Related Ports</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <app-unloco
                    label="Port of Receipt"
                    formControlName="portOfReceipt"
                    [extendedWidth]="true"
                    class="unloco-width"
                  ></app-unloco>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <div class="row">
                  <app-unloco
                    label="Final Destination Port"
                    formControlName="portOfDestination"
                    [extendedWidth]="true"
                    class="unloco-width"
                  ></app-unloco>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <app-date-picker
                  label="Cargo Ready Date"
                  [extendedWidth]="true"
                  [dateValue]="form.get('cargoReadyDateValue')?.value"
                  (dateSelected)="onDateSelected('cargoReadyDateValue', $event)"
                  [required]="true"
                  [marked]="cargoReadyDateMarked"
                  class="date-width"
                ></app-date-picker>
              </div>
            </div>
            <div class="col-3">
              <div class="form-field">
                <app-date-picker
                  label="Estimated Delivery Date"
                  [extendedWidth]="true"
                  [dateValue]="form.get('estimatedDeliveryDateValue')?.value"
                  (dateSelected)="onDateSelected('estimatedDeliveryDateValue', $event)"
                  [required]="true"
                  [marked]="estimatedDeliveryDateMarked"
                  class="date-width"
                ></app-date-picker>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</form>
